import React from 'react'
import {
  Container,
  CommonBanner,
  Section,
  Seo,
} from '@components'
import { FaqPanel } from '../../components/site/faq'
import { PageLayout } from '../../components/site/layout/page-layout'

const FaqPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>

      <CommonBanner title="Approov Frequently Asked Questions" />

      <Section>
        <Container className="container-sm">
          <FaqPanel className="py-16" />
        </Container>
      </Section>
    </PageLayout>
  )
}

export default FaqPage
